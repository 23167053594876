import Navbar from '../components/Navbar';
import { mp } from '../lib/mixpanel';
import styles from '../styles/Dashboard.module.css';
import { auth, googleAuthProvider } from '../lib/firebase';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import Link from 'next/link';

export default function Signup() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(null);
  const [loginError, setLoginError] = useState(null);

  const login = async (email, password) => {
    try {
      const { user } = await auth.signInWithEmailAndPassword(email, password);
      mp.identify(user.uid);
      mp.track('login', {
        userID: user.uid,
      });
      router.push('/dashboard');
    } catch (err) {
      setLoginError('User does not exist, or password does not match');
    }
  };

  const google = async () => {
    await auth.signInWithPopup(googleAuthProvider);
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });

  const sendForm = async (data) => {
    console.log(data);
    setIsLoading(true);

    await login(data.email, data.password);

    setIsLoading(false);
  };

  return (
    <>
      <Navbar />

      <div className={`${styles['auth-container']} red`}>
        <div className={`${styles['dashboard-container-max']} red`}>
          <div className={`${styles['profile-item-container']} red`}>
            <div className={`${styles['form-title']} red`}>Log in</div>
            <form onSubmit={handleSubmit(sendForm)}>
              <div className={`${styles['input-label']} red`}>Email</div>
              <input
                className={`${styles['input-text']} red`}
                autoComplete="off"
                {...register('email', {
                  required: true,
                  pattern: /^([\w\d._\-#+])+@([\w\d._\-#]+[.][\w\d._\-#]+)+$/i,
                })}
              />
              {errors.email?.type === 'required' && (
                <div className={`${styles['input-error']} red`}>
                  Email required
                </div>
              )}

              {errors.email?.type === 'pattern' && (
                <div className={`${styles['input-error']} red`}>
                  Valid email required
                </div>
              )}

              <div className={`${styles['input-space']} red`} />

              {/* INPUT */}
              {/* INPUT */}
              {/* INPUT */}
              <div className={`${styles['input-label']} red`}>Password</div>

              <input
                className={`${styles['input-text']} red`}
                autoComplete="off"
                type="password"
                {...register('password', {
                  required: true,
                })}
              />
              {errors.password?.type === 'required' && (
                <div className={`${styles['input-error']} red`}>
                  Password required
                </div>
              )}
              {loginError && (
                <div className={`${styles['input-error']} red`}>
                  {loginError}
                </div>
              )}

              <button
                type="submit"
                className={`${styles['login-btn']} red`}
                disabled={isLoading}
              >
                Log in
              </button>
            </form>

            <div className={`${styles['already-account']} red`}>
              Don&apos;t have an account?{' '}
              <Link href="/start">
                <span className={`${styles['login-here']} red`}>
                  Sign up here
                </span>
              </Link>
              .
            </div>
            <div className={`${styles['already-account']} red`}>
              Forgot your password?{' '}
              <Link href="/forgot">
                <span className={`${styles['login-here']} red`}>
                  Reset here
                </span>
              </Link>
              .
            </div>
            {/* <button onClick={google}>Google Login</button> */}
          </div>
        </div>
      </div>
    </>
  );
}
